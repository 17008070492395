import React from "react";

const AttributesFormatter = (props) => (
  <div>
    {props.attr.split("_").map((a) => {
      let aparts = a.split("=");
      if (aparts.length !== 2) {
        return "";
      }
      return (
        <div className="attr-row" key={aparts[0]}>
          <div className="attr-field">{aparts[0]}:</div>
          <div className="attr-value">{aparts[1]}</div>
        </div>
      );
    })}
  </div>
);

export default AttributesFormatter;
