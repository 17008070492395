// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the home page
import React from "react";
import { isAuthenticated } from "./services/AuthService";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return (
      <Navigate
        to={{
          pathname: "/login/",
          state: { from: window.location },
          _: localStorage.setItem("from", window.location.pathname),
        }}
        replace
      />
    );
  }
  return children;
};

export default PrivateRoute;
