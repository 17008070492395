import React from "react";
import { FormControl } from "react-bootstrap";

class SubcategorySelect extends React.Component {
  constructor(props) {
    super(props);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
  }

  onSubcategoryChange(event) {
    this.props.onSubcategoryChange(event.target.value);
  }

  render() {
    return (
      <FormControl
        required
        as="select"
        name="SubCatID"
        onChange={this.onSubcategoryChange}
        value={this.props.selected}
        disabled={this.props.subcategories.length === 0}
      >
        <option value="0" key="0">
          Select a subcategory
        </option>
        {this.props.subcategories.map(subcategory => {
          return (
            <option value={subcategory.ID} key={subcategory.ID}>
              {subcategory.Name}
            </option>
          );
        })}
      </FormControl>
    );
  }
}

export default SubcategorySelect;
