import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { fetchSelf } from "./services/DataService";
import LoadMask from "./components/LoadMask";
import jwt_decode from "jwt-decode";

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }

  componentWillMount() {
    let id_token = window.location.hash
      .substr(1)
      .split("id_token=")[1]
      .split("&")[0];
    let nonce = localStorage.getItem("nonce");
    localStorage.removeItem("nonce");
    let decoded = jwt_decode(id_token);
    if (nonce !== decoded.nonce) return;
    localStorage.setItem("id_token", id_token);

    fetchSelf().then((user) => {
      if (
        user == null ||
        user === "undefined" ||
        JSON.stringify(user) === "undefined"
      ) {
        localStorage.removeItem("user");
      } else {
        localStorage.setItem("user", JSON.stringify(user));
      }
      let from = localStorage.getItem("from");
      if (from != null) {
        localStorage.removeItem("from");
        window.location.href = from;
      } else {
        window.location.href = "/found";
      }
    });
  }

  render() {
    if (this.state.redirect) return <Navigate to={this.state.redirect} />;
    return <LoadMask />;
  }
}

export default Callback;
