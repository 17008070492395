import jwt_decode from "jwt-decode";

const Login = () => {
  let randomString = function (length) {
    var text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };
  let nonce = randomString(10);
  localStorage.setItem("nonce", nonce);
  var url = "https://login.microsoftonline.com/";
  url += process.env.REACT_APP_APPID;
  url += "/oauth2/v2.0/authorize";
  url += "?response_type=id_token&client_id=";
  url += process.env.REACT_APP_CLIENTID;
  url += "&redirect_uri=";
  url += process.env.REACT_APP_REDIRECT_URI + "%2Fcallback";
  url += "&scope=openid+profile&state=initial&prompt=login&nonce=";
  url += nonce;
  window.location.href = url;
  return <></>;
};

const Logout = () => {
  localStorage.clear();
  let url =
    "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" +
    process.env.REACT_APP_REDIRECT_URI;
  window.location.href = url;
  return <></>;
};

const isAuthenticated = () => {
  let id_token = window.localStorage.getItem("id_token");
  var decoded = {};
  if (id_token) {
    decoded = jwt_decode(id_token);
  }
  if (decoded && decoded.preferred_username) {
    return true;
  }
  return false;
};

export { isAuthenticated, Login, Logout };
