import React from "react";
import { FormControl } from "react-bootstrap";

class FoundLocationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onFoundLocationChange = this.onFoundLocationChange.bind(this);
  }

  onFoundLocationChange(event) {
    this.props.onFoundLocationChange(event.target.value);
  }

  render() {
    return (
      <FormControl
        required
        as="select"
        name="LocationID"
        onChange={this.onFoundLocationChange}
        value={this.props.selected}
        disabled={this.props.foundLocations.length === 0}
      >
        <option value="0" key="0">
          Select a stored location
        </option>
        {this.props.foundLocations.map((foundLocation) => {
          return (
            <option value={foundLocation.ID} key={foundLocation.ID}>
              {foundLocation.Name}
            </option>
          );
        })}
      </FormControl>
    );
  }
}

export default FoundLocationSelect;
