import React from "react";
import { Navigate } from "react-router-dom";
import { Form, Button, Row, Col, Image, Alert } from "react-bootstrap";
import {
  ticketMaster,
  dateToString,
  stringToDate,
} from "./services/UtilityService";
import {
  fetchFoundItem,
  fetchFoundImage,
  fetchCategories,
  fetchSubcategories,
  fetchAttributes,
  fetchLocations,
  fetchTicket,
  fetchFoundMatches,
  putFoundItem,
  putFoundImage,
  postFoundItem,
  deleteFoundItem,
  deleteFoundImage,
} from "./services/DataService";
import withRouter from "./withRouter";
import CategorySelect from "./components/CategorySelect";
import SubcategorySelect from "./components/SubcategorySelect";
import AttributeFields from "./components/AttributeFields";
import ResolutionSelect from "./components/ResolutionSelect";
import FoundLocationSelect from "./components/FoundLocationSelect";
import DeleteAlert from "./components/DeleteAlert";
import ErrorAlert from "./components/ErrorAlert";
import LostCard from "./components/LostCard";
import LoadMask from "./components/LoadMask";
import Webcam from "react-webcam";
import DatePicker from "react-datepicker";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import "./style/form.scss";
import "./style/add-found-print.scss";
import "react-datepicker/dist/react-datepicker.css";

class AddFound extends React.Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    let isSuperuser = false;
    if (user !== null) {
      user = JSON.parse(user);
      if (user.is_superuser) {
        isSuperuser = true;
      }
    }

    this.state = {
      loading: true,
      user: user,
      isSuperuser: isSuperuser,
      canEdit: false,
      cameraOpen: false,
      Image: "",
      redirect: false,
      id: props.params.id,
      CatID: "0",
      SubCatID: "0",
      ResolutionID: 4,
      dateFound: new Date(),
      categories: [],
      subcategories: [],
      attributes: [],
      foundLocations: [],
    };
    this.webcamRef = React.createRef();
    this.formRef = React.createRef();
    this.handleDateChange = this.handleDateChange.bind(this);
    this.foundItemCallback = this.foundItemCallback.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.onAttributesChange = this.onAttributesChange.bind(this);
    this.onFoundLocationChange = this.onFoundLocationChange.bind(this);
    this.onMatchWithTicket = this.onMatchWithTicket.bind(this);
    this.onMatchWithoutTicket = this.onMatchWithoutTicket.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    Promise.all([fetchCategories(), fetchLocations()])
      .then(([categories, foundLocations]) => {
        this.setState({
          categories: categories,
          foundLocations: foundLocations,
        });
        if (this.state.id) {
          fetchFoundItem(this.state.id).then(this.foundItemCallback);
        } else {
          this.setState({ canEdit: true, loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  foundItemCallback = (item) => {
    this.setState({
      ...item,
      canEdit: item.DepartmentID === this.state.user.department_id,
      dateFound: stringToDate(item.When),
    });
    this.onCategoryChange(item.CatID);
    this.onSubcategoryChange(item.SubCatID);
    this.handleResolutionChange(item.ResolutionID);
    item.Attr.substr(1)
      .split("_")
      .forEach((a) => {
        let parts = a.split("=");
        let key = "attribute_" + parts[0];
        this.setState({ [key]: parts[1] });
      });
    fetchFoundImage(this.state.id).then((image) => {
      this.setState({ Image: image, loading: false });
    });
    fetchFoundMatches(this.state.id).then((matches) => {
      if (typeof matches !== "string" && matches.length > 0) {
        this.setState({ matches: matches });
      }
    });
  };

  handleSubmit = (event) => {
    if (!this.state.canEdit && !this.state.isSuperuser) {
      return;
    }
    let form;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      form = event.currentTarget;
    } else {
      form = this.formRef.current;
    }

    var isValid = true;
    if (form.checkValidity() === false) {
      isValid = false;
    }

    let ticket;
    if (this.state.Ticket) {
      ticket = this.state.Ticket;
    } else {
      ticket = ticketMaster();
      this.setState({ Ticket: ticket });
    }

    let item = {
      Attr: "",
      Ticket: ticket,
      When: dateToString(this.state.dateFound),
    };
    const data = new FormData(form);
    let it = data.entries();
    let result = it.next();
    while (!result.done) {
      let key = result.value[0];
      let value = result.value[1];
      if (!key.startsWith("attribute_")) {
        item[key] = value;
      }
      result = it.next();
    }
    Object.keys(this.state).map((key) =>
      key.startsWith("attribute_")
        ? (item.Attr += key.substring(9) + "=" + this.state[key])
        : ""
    );
    item.ResolutionID = this.state.ResolutionID;
    item.CatID = parseInt(item.CatID, 10);
    item.SubCatID = parseInt(item.SubCatID, 10);
    item.LocationID = parseInt(item.LocationID, 10);
    item.Image = this.state.Image;
    item.Price = parseFloat(parseFloat(this.state.Price).toFixed(2));
    if (this.state.MatchedID) {
      item.MatchedID = this.state.MatchedID;
    }
    if (isValid) {
      if (this.state.id) {
        item.ID = parseInt(this.state.id, 10);
        putFoundItem(item).then(() => this.setState({ redirect: "/found" }));
      } else {
        postFoundItem(item).then(() => this.setState({ redirect: "/found" }));
      }
    }
  };

  submitCallback = (item) => {
    if (item.Image !== "") {
      putFoundImage({ ID: item.ID, Image: item.Image });
    } else {
      deleteFoundImage(item.ID);
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleDateChange = (date) => {
    if (!this.state.canEdit) {
      return;
    }
    this.setState({ dateFound: date });
  };

  onCategoryChange(id) {
    fetchSubcategories(id).then((subcategories) => {
      this.setState({ subcategories: subcategories });
      subcategories
        .filter((subcategories) => subcategories.ID === this.state.SubCatID)
        .map((item, _) =>
          this.setState({ suggestedPrice: item.Price, category: item.Name })
        );
    });
    if (!this.state.canEdit) {
      return;
    }
    this.setState({ CatID: id, attributes: [] });
  }

  onSubcategoryChange(id) {
    fetchAttributes(id).then((attributes) => {
      if (!this.state.canEdit) {
        return;
      }
      this.setState({ attributes: attributes });
    });
    if (!this.state.canEdit) {
      return;
    }
    this.state.subcategories
      .filter((subcategories) => subcategories.ID === this.state.SubCatID)
      .map((item, _) =>
        this.setState({ suggestedPrice: item.Price })
      );
    this.setState({ SubCatID: id });
  }

  onAttributesChange(name, value) {
    if (!this.state.canEdit || !value) {
      return;
    }
    this.setState({ [name]: value });
  }

  handleResolutionChange = (id) => {
    if (!this.state.canEdit) {
      return;
    }
    this.setState({ ResolutionID: id });
  };

  onFoundLocationChange(id) {
    if (!this.state.canEdit) {
      return;
    }
    this.setState({ LocationID: id });
  }

  onMatchWithTicket() {
    this.setState({ matching: false });
    if (!this.state.MatchedTicket) {
      return this.setState({
        error: "No ticket number provided.",
      });
    }
    if (this.state.MatchedTicket.length !== 10) {
      return this.setState({
        error: "Invalid ticket number.",
      });
    }
    fetchTicket(this.state.MatchedTicket).then((item) => {
      if (!item) {
        this.setState({
          error: "Unable to find a matching item for the given ticket number.",
        });
      }
      if (item["Type"] === "found") {
        this.setState({
          error:
            "The given ticket number appears to be for another found item.",
        });
      }
      if (item["Type"] !== "lost") {
        this.setState({
          error:
            "The given ticket number does not appear to be for a lost item.",
        });
      }
      this.setState(
        { ResolutionID: 2, MatchedID: item["ID"] },
        this.handleSubmit
      );
    });
  }

  onMatchWithoutTicket() {
    this.setState(
      { matching: false, ResolutionID: 2, MatchedID: false },
      this.handleSubmit
    );
  }

  render() {
    if (this.state.redirect) return <Navigate to={this.state.redirect} />;

    if (this.state.loading) return <LoadMask />;

    const videoConstraints = {
      facingMode: "environment",
      width: 240,
      height: 240,
    };
    let cameraButton;
    if (this.state.canEdit) {
      cameraButton = (
        <Button
          variant="secondary"
          className={`btn-camera open-camera ${
            this.state.Image ? "with-image" : "no-image"
          }`}
          onClick={() => this.setState({ cameraOpen: true })}
        >
          <div className="glyphicon glyphicon-camera">
            <div className="hidden">Open Camera</div>
          </div>
        </Button>
      );
    }
    if (this.state.cameraOpen) {
      cameraButton = (
        <div>
          <Webcam
            audio={false}
            ref={this.webcamRef}
            videoConstraints={videoConstraints}
          />
          <Button
            variant="success"
            className="btn-confirm-image"
            onClick={() =>
              this.setState({
                Image: this.webcamRef.current.getScreenshot(),
                cameraOpen: false,
              })
            }
          >
            <div className="glyphicon glyphicon-ok">
              <div className="hidden">Capture Photo</div>
            </div>
          </Button>
          <Button
            variant="danger"
            className="btn-cancel-image"
            onClick={() => this.setState({ cameraOpen: false })}
          >
            <div className="glyphicon glyphicon-remove">
              <div className="hidden">Cancel</div>
            </div>
          </Button>
        </div>
      );
    }
    let imageView;
    if (this.state.Image !== "" && !this.state.cameraOpen) {
      imageView = (
        <>
          <Col className="image-column">
            <div className="image-container">
              <Image
                src={this.state.Image}
                style={{ border: "1px solid rgb(206, 212, 218)" }}
                width="480px"
                height="480px"
                roundedCircle
              />
              {this.state.canEdit ? (
                <Button
                  variant="danger"
                  className="btn-remove-image"
                  onClick={() => this.setState({ Image: "" })}
                >
                  <div className="glyphicon glyphicon-remove">
                    <div className="hidden">Remove Image</div>
                  </div>
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </>
      );
    }
    let alert;
    if (this.state.alert) {
      alert = (
        <DeleteAlert
          onConfirm={() => {
            deleteFoundItem(this.state.id).then(() =>
              this.setState({ alert: false, redirect: "/found" })
            );
          }}
          onCancel={() => this.setState({ alert: false })}
        />
      );
    }
    let matchButton;
    if (this.state.id && this.state.canEdit) {
      matchButton = (
        <div className="d-flex justify-content-center btn-match">
          <Button
            variant="outline-success"
            onClick={() => {
              this.setState({ matching: true });
            }}
          >
            Return
          </Button>
        </div>
      );
    }
    let matchAlert;
    if (this.state.matching) {
      matchAlert = (
        <Alert variant="success" className="resolutionAlert">
          <Alert.Heading>Please input a lost ticket number</Alert.Heading>
          <hr />
          <Row>
            <Form.Label column sm="2">
              Ticket #
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="MatchedTicket"
                onChange={this.onChange}
                value={this.state.MatchedTicket || ""}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button onClick={this.onMatchWithTicket} variant="success">
              Submit
            </Button>
            <Button
              onClick={this.onMatchWithoutTicket}
              variant="outline-success"
            >
              Return without a match
            </Button>
            <Button
              onClick={() => this.setState({ matching: false })}
              variant="outline-danger"
            >
              Cancel
            </Button>
          </div>
        </Alert>
      );
    }
    let barcode;
    if (this.state.Ticket && this.state.canEdit) {
      barcode = (
        <div className="barcode btn-barcode" onClick={() => window.print()}>
          <Barcode value={this.state.Ticket} />
          <div className="icon-print glyphicon glyphicon-print">
            <div className="hidden">Print Barcode</div>
          </div>
        </div>
      );
    }
    let qrcode;
    if (this.state.Ticket && this.state.canEdit) {
      qrcode = (
        <div className="qrcode">
          <QRCode value={this.state.Ticket} />
        </div>
      );
    }
    let deleteButton;
    if (this.state.id && this.state.canEdit) {
      deleteButton = (
        <div className="d-flex justify-content-center btn-delete">
          <Button
            variant="outline-danger"
            onClick={() => this.setState({ alert: true })}
          >
            Delete
          </Button>
        </div>
      );
    }
    let errorAlert;
    if (this.state.error) {
      errorAlert = (
        <ErrorAlert
          error={this.state.error}
          onClose={() => {
            this.setState({ error: false });
          }}
        />
      );
    }
    let matchCards;
    if (this.state.matches) {
      matchCards = (
        <div className="matches-container">
          <div className="d-flex justify-content-center potential-matches-title">
            Potential Matches
          </div>
          <div className="justify-content-center potential-matches">
            {this.state.matches.map((match) => {
              let item = match.LostItem;
              return (
                <LostCard
                  key={item.ID}
                  item={item}
                  onClick={() =>
                    this.setState({ redirect: `/lost/${item.ID}` })
                  }
                />
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div>
        {alert}
        {matchAlert}
        {errorAlert}
        <div className="form-title">
          {this.state.id ? "Edit" : "Add"} Found Inventory
        </div>
        <Form className="form" onSubmit={this.handleSubmit} ref={this.formRef}>
          <Form.Label className="form-header">Property Information</Form.Label>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Date Found
            </Form.Label>
            <Col sm="9">
              <DatePicker
                className="form-control"
                selected={this.state.dateFound}
                onChange={this.handleDateChange}
              />
            </Col>
            <Form.Label column sm="3">
              Category
            </Form.Label>
            <Col sm="9">
              <CategorySelect
                onCategoryChange={this.onCategoryChange}
                categories={this.state.categories}
                selected={this.state.CatID || "0"}
              />
            </Col>
            <Form.Label column sm="3">
              Subcategory
            </Form.Label>
            <Col sm="9">
              <SubcategorySelect
                onSubcategoryChange={this.onSubcategoryChange}
                subcategories={this.state.subcategories}
                selected={this.state.SubCatID || "0"}
              />
            </Col>
            <AttributeFields
              onAttributesChange={this.onAttributesChange}
              attributes={this.state.attributes}
              selected={this.state.Attr}
            />
            <Form.Label column sm="3">
              Found Location
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="Where"
                onChange={this.onChange}
                value={this.state.Where || ""}
              />
            </Col>
            {this.state.canEdit ? (
              <>
                <Form.Label column sm="3">
                  Stored Location
                </Form.Label>
                <Col sm="9">
                  <FoundLocationSelect
                    onFoundLocationChange={this.onFoundLocationChange}
                    foundLocations={this.state.foundLocations}
                    selected={this.state.LocationID || "0"}
                  />
                </Col>
              </>
            ) : (
              ""
            )}
            {this.state.isSuperuser && this.state.canEdit ? (
              <>
                <Form.Label column sm="3">
                  Price
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    name="Price"
                    type="number"
                    onChange={this.onChange}
                    value={this.state.Price || ""}
                  />
                </Col>
                <Form.Label column sm="3">
                  Default Price
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    disabled
                    onChange={this.onChange}
                    value={
                      this.state
                        .suggestedPrice ? `$${this.state.suggestedPrice}` :
                      "None set"
                    }
                  />
                </Col>
              </>
            ) : (
              ""
            )}
            <Form.Label column sm="3">
              Description
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                name="Desc"
                rows="4"
                onChange={this.onChange}
                value={this.state.Desc || ""}
              />
            </Col>
            <ResolutionSelect
              className="mt-2 mb-4"
              onResolutionChange={(id) => {
                if (this.state.canEdit) {
                  this.setState({ ResolutionID: id });
                }
              }}
              resolution={this.state.ResolutionID ? this.state.ResolutionID : 4}
            ></ResolutionSelect>
            {imageView}
          </Form.Group>
          {cameraButton}
          <div className="d-flex justify-content-center">
            {matchButton}
            {this.state.canEdit ? (
              <Button variant="primary" type="submit" className="btn-submit">
                {this.state.id ? "Update" : "Submit"}
              </Button>
            ) : (
              ""
            )}
            {deleteButton}
          </div>
        </Form>
        {barcode}
        {qrcode}
        {matchCards}
      </div>
    );
  }
}

export default withRouter(AddFound);
