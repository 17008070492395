export default function RouteError() {
  return (
    <div id="error-page">
      <h1>404</h1>
      <p>In a Lost & Found app?</p>
      <p>
        <i>Bit ironic wouldn't you say?</i>
      </p>
    </div>
  );
}
