import React from "react";
import { Navigate } from "react-router-dom";
import { Alert, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import {
  ticketMaster,
  dateToString,
  stringToDate,
} from "./services/UtilityService";
import {
  fetchLostItem,
  fetchCategories,
  fetchSubcategories,
  fetchAttributes,
  fetchLostMatches,
  putLostItem,
  postLostItem,
  deleteLostItem,
} from "./services/DataService";
import withRouter from "./withRouter";
import FoundCard from "./components/FoundCard";
import CategorySelect from "./components/CategorySelect";
import SubcategorySelect from "./components/SubcategorySelect";
import AttributeFields from "./components/AttributeFields";
import DeleteAlert from "./components/DeleteAlert";
import DatePicker from "react-datepicker";
import Barcode from "react-barcode";
import "./style/form.scss";
import "react-datepicker/dist/react-datepicker.css";

class AddLost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subCatReady: false,
      subCatLoading: false,
      validated: false,
      submitted: false,
      id: props.params.id,
      CatID: "0",
      SubCatID: "0",
      dateLost: new Date(),
      categories: [],
      subcategories: [],
      attributes: [],
    };
  }

  componentDidMount() {
    fetchCategories().then((categories) =>
      this.setState({ categories: categories })
    );
    if (this.state.id) {
      fetchLostItem(this.state.id).then(this.lostItemCallback);
    }
  }

  lostItemCallback = (item) => {
    this.setState({ ...item });
    this.onCategoryChange(item.CatID);
    this.onSubcategoryChange(item.SubCatID);
    this.setState({ dateLost: stringToDate(item.When) });
    item.Attr.substr(1)
      .split("_")
      .forEach((a) => {
        let parts = a.split("=");
        let key = "attribute_" + parts[0];
        this.setState({ [key]: parts[1] });
      });
    fetchLostMatches(this.state.id).then(this.lostMatchesCallback);
  };

  lostMatchesCallback = (matches) => {
    if (typeof matches !== "string" && matches.length > 0) {
      this.setState({ matches: matches });
    }
  };

  submitCallback = () => {
    this.setState({
      alert: (
        <Alert variant="primary">
          <Alert.Heading>Thank you for reporting!</Alert.Heading>
          <hr />
          <p>
            We'll be on the look out for your item and contact you if it's
            found.
            <br /> Your ticket number is {this.state.Ticket}
          </p>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => (window.location = "/")}
              variant="outline-primary"
            >
              Close
            </Button>
          </div>
        </Alert>
      ),
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true, submitted: true });

    const form = event.currentTarget;
    var isValid = form.checkValidity();

    let ticket;
    if (this.state.Ticket) {
      ticket = this.state.Ticket;
    } else {
      ticket = ticketMaster();
      this.setState({ Ticket: ticket });
    }

    let item = {
      Attr: "",
      Ticket: ticket,
      When: dateToString(this.state.dateLost),
    };
    const data = new FormData(event.target);
    let it = data.entries();
    let result = it.next();
    while (!result.done) {
      let key = result.value[0];
      let value = result.value[1];
      if (!key.startsWith("attribute_")) {
        item[key] = value;
      }
      result = it.next();
    }
    item.CatID = parseInt(item.CatID, 10);
    item.SubCatID = parseInt(item.SubCatID, 10);
    item.Attr = "";
    Object.keys(this.state).map((key) =>
      key.startsWith("attribute_")
        ? (item.Attr += key.substring(9) + "=" + this.state[key])
        : ""
    );

    if (isValid) {
      if (this.state.id) {
        item.ID = parseInt(this.state.id, 10);
        item.ContactID = this.state.ContactID;
        putLostItem(item).then(this.submitCallback);
      } else {
        postLostItem(item, this.submitCallback);
      }
    } else {
      this.setState({ submitted: false });
    }
  };

  onCategoryChange = (id) => {
    this.setState({ subCatReady: false, subCatLoading: true });
    fetchSubcategories(id).then((subcategories) =>
      this.setState({
        subCatReady: true,
        subCatLoading: false,
        subcategories: subcategories,
        CatID: id,
        attributes: [],
      })
    );
  };

  onSubcategoryChange = (id) => {
    fetchAttributes(id).then((attributes) =>
      this.setState({ SubCatID: id, attributes: attributes })
    );
  };

  onAttributesChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onPhoneChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    if (this.state.alert) {
      return this.state.alert;
    }
    let deleteAlert;
    if (this.state.deleteAlert) {
      deleteAlert = (
        <DeleteAlert
          onConfirm={() => {
            deleteLostItem(this.state.id).then(() =>
              this.setState({ deleteAlert: false, redirect: "/lost" })
            );
          }}
          onCancel={() => this.setState({ deleteAlert: false })}
        />
      );
    }
    let barcode;
    if (this.state.Ticket && this.state.id) {
      barcode = (
        <div className="barcode">
          <Barcode value={this.state.Ticket} />
        </div>
      );
    }
    let deleteButton;
    if (this.state.id) {
      deleteButton = (
        <div className="d-flex justify-content-center btn-delete">
          <Button
            variant="danger"
            onClick={() => this.setState({ deleteAlert: true })}
          >
            Delete
          </Button>
        </div>
      );
    }

    let subCatSpinner = this.state.subCatLoading ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      ""
    );

    let subCatLabel =
      this.state.subCatLoading || this.state.subCatReady ? (
        <Form.Label column sm="3"htmlFor="SubCatID">
          Subcategory
        </Form.Label>
      ) : (
        ""
      );

    let subCat = this.state.subCatReady ? (
      <SubcategorySelect
        onSubcategoryChange={this.onSubcategoryChange}
        subcategories={this.state.subcategories}
        selected={this.state.SubCatID || "0"}
      />
    ) : (
      subCatSpinner
    );

    let matchCards;
    if (this.state.matches) {
      matchCards = (
        <div style={{ paddingBottom: "30px" }}>
          <div className="d-flex justify-content-center potential-matches-title">
            Potential Matches
          </div>
          <div className="d-flex justify-content-center potential-matches">
            {this.state.matches.map((match) => {
              let item = match.FoundItem;
              return (
                <FoundCard
                  key={item.ID}
                  item={item}
                  onClick={() =>
                    this.setState({ redirect: `/found/${item.ID}` })
                  }
                />
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div>
        {deleteAlert}
        <div className="form-title">
          {this.state.id ? "Edit" : "Report"} a Lost Item
        </div>
        <Form
          className="form"
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <Form.Label className="form-header">Contact Information</Form.Label>
          <Form.Group as={Row}>
            <Form.Label column sm="3" htmlFor="ContactName">
              Name
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                type="text"
                autoComplete="name"
                name="ContactName"
                placeholder="Viking McRealname"
                onChange={this.onChange}
                value={this.state.ContactName || ""}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a name.
              </Form.Control.Feedback>
            </Col>
            <Form.Label column sm="3" htmlFor="ContactEmail">
              Email
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                type="email"
                autoComplete="email"
                name="ContactEmail"
                placeholder="viking@wwu.edu"
                onChange={this.onChange}
                value={this.state.ContactEmail || ""}
              />
              <Form.Control.Feedback type="invalid">
                Please provide an email.
              </Form.Control.Feedback>
            </Col>
            <Form.Label column sm="3" htmlFor="ContactPhone">
              Phone
            </Form.Label>
            <Col sm="10" className="phone-input-container">
              <Form.Control
                required
                autoComplete="tel"
                type="tel"
                name="ContactPhone"
                pattern="[0-9]{10}"
                placeholder="3609991234"
                onChange={this.onPhoneChange}
                value={this.state.ContactPhone || ""}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a phone number (ex: 3609991234).
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Label className="form-header">Property Information</Form.Label>
          <Form.Group as={Row}>
            <Form.Label column sm="3" htmlFor="DateLost">
              Date Lost
            </Form.Label>
            <Col sm="9">
              <DatePicker
                className="form-control"
                name="DateLost"
                selected={this.state.dateLost}
                onChange={(date) => this.setState({ dateLost: date })}
              />
            </Col>
            <Form.Label column sm="3" htmlFor="Where">
              Location
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                type="text"
                name="Where"
                placeholder="Viking Union"
                onChange={this.onChange}
                value={this.state.Where || ""}
              />
              <Form.Control.Feedback type="invalid">
                Tell us where you lost the item.
              </Form.Control.Feedback>
            </Col>
            <Form.Label column sm="3" htmlFor="Desc">
              Description
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                as="textarea"
                name="Desc"
                rows="4"
                onChange={this.onChange}
                value={this.state.Desc || ""}
              />
            </Col>
            <Form.Label column sm="3" htmlFor="CatID">
              Category
            </Form.Label>
            <Col sm="9">
              <CategorySelect
                onCategoryChange={this.onCategoryChange}
                categories={this.state.categories}
                selected={this.state.CatID || "0"}
              />
            </Col>
            {subCatLabel}
            <Col sm="9">{subCat}</Col>
            <AttributeFields
              onAttributesChange={this.onAttributesChange}
              attributes={this.state.attributes}
              selected={this.state.Attr}
            />
          </Form.Group>
          <div className="d-flex justify-content-center mt-4">
            <div style={{ width: "95px" }}></div>
            <Button
              variant="primary"
              type="submit"
              className="btn-submit"
              disabled={this.state.SubCatID === "0" || this.state.submitted}
            >
              {this.state.id ? "Update" : "Submit"}
            </Button>
            {deleteButton}
          </div>
        </Form>
        {barcode}
        {matchCards}
      </div>
    );
  }
}

export default withRouter(AddLost);
