import React from "react";
import { Card } from "react-bootstrap";

class LostCard extends React.Component {
  render() {
    let titleString = this.props.item.Attr.substr(1)
      .split("_")
      .map(item => {
        return item.split("=").pop();
      })
      .join(" ");
    let imgSrc = require("../images/subcategories/" + this.props.item.SubCatID + ".svg");
    this.props.item.Where =
      this.props.item.Where === "" ? "Unknown" : this.props.item.Where;
    return (
      <Card onClick={this.props.onClick} key={this.props.item.ID}>
        <Card.Img variant="top" className="card-svg" src={imgSrc} />
        <Card.Body>
          <Card.Title>{titleString}</Card.Title>
          <Card.Text>{this.props.item.Desc}</Card.Text>
          <div className="when-where">
            <div className="glyphicon glyphicon-map-marker"></div>&nbsp;
            {this.props.item.Where}
            <br />
            <div className="glyphicon glyphicon-calendar"></div>&nbsp;
            {this.props.item.When}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default LostCard;
