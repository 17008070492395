import React from "react";
import { Alert, Button } from "react-bootstrap";

class ErrorAlert extends React.Component {
  render() {
    return (
      <Alert className="resolutionAlert" variant={this.props.variant}>
        <Alert.Heading>
          {this.props.header || "An error has occurred"}
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-center">{this.props.error}</div>
        <div className="d-flex justify-content-end">
          <Button onClick={this.props.onClose} variant={this.props.variant}>
            Close
          </Button>
        </div>
      </Alert>
    );
  }
}

export default ErrorAlert;
