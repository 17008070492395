import React from "react";
import { Alert, Button } from "react-bootstrap";

class DeleteAlert extends React.Component {
  render() {
    return (
      <Alert className="resolutionAlert" variant="danger">
        <Alert.Heading>Danger Will Robinson!</Alert.Heading>
        <hr />
        <div className="d-flex justify-content-center">
          Deletion is permanent! Only delete an item if it was created in error.
        </div>
        <div className="d-flex justify-content-end">
          <Button onClick={this.props.onConfirm} variant="outline-danger">
            Delete Permanently
          </Button>
          <Button onClick={this.props.onCancel} variant="danger">
            Cancel
          </Button>
        </div>
      </Alert>
    );
  }
}

export default DeleteAlert;
