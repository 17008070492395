let fetchSelf = () => {
  return fetchWithAuthPromise("GET", "/user");
};

let fetchUsers = () => {
  return fetchWithAuthPromise("GET", "/users");
};

let fetchSettings = (params) => {
  let url = "/setting";
  if (params && params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};

let fetchLost = (params) => {
  let url = "/lost";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};

let fetchFound = (params) => {
  let url = "/found";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};

let fetchReport = (params) => {
  let url = "/report";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};

let fetchSetting = (id) => {
  return fetchWithAuthPromise("GET", `/setting/${id}`);
};

let fetchLostItem = (id) => {
  return fetchWithAuthPromise("GET", `/lost/${id}`);
};

let fetchFoundItem = (id) => {
  return fetchWithAuthPromise("GET", `/found/${id}`);
};

let fetchFoundImage = (id) => {
  return fetchWithAuthPromise("GET", `/foundimage/${id}`);
};

let fetchTicket = (id) => {
  return fetchWithAuthPromise("GET", `/ticket/${id}`);
};

let fetchDepartments = () => {
  return fetchWithoutAuthPromise("GET", `/departments`);
};

let fetchCategories = () => {
  return fetchWithoutAuthPromise("GET", `/categories`);
};

let fetchSubcategories = (categoryID) => {
  let url = `/categories/${categoryID}/subcategories`;
  if (categoryID === "0") {
    url = `/subcategories`;
  }
  return fetchWithoutAuthPromise("GET", url);
};

let fetchAttributes = (subcategoryID) => {
  if (subcategoryID === "0") {
    return new Promise((r) => {
      r([]);
    });
  }
  return fetchWithoutAuthPromise(
    "GET",
    `/subcategories/${subcategoryID}/attributes`
  );
};

let fetchLocations = () => {
  return fetchWithAuthPromise("GET", `/locations`);
};

let fetchLostMatches = (id, callback) => {
  return fetchWithAuthPromise("GET", `/lost/${id}/match`);
};

let fetchFoundMatches = (id) => {
  return fetchWithAuthPromise("GET", `/found/${id}/match`);
};

let fetchAllSubcategories = () => {
  return fetchWithoutAuthPromise("GET", `/subcategories`);
};

let fetchSales = (params) => {
  let url = "/sales";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithoutAuthPromise("GET", url);
};

let fetchSaleItems = (saleID) => {
  return fetchWithoutAuthPromise("GET", `/sale/${saleID}/saleitems`);
};

let fetchSalesReport = (params) => {
  let url = "/reportsale";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithoutAuthPromise("GET", url);
};

let putSubcategories = (item) => {
  return fetchWithAuthPromise("PUT", `/subcategories`, item);
};

let putUser = (user) => {
  return fetchWithAuthPromise("PUT", `/users`, user);
};

let putSetting = (setting) => {
  return fetchWithAuthPromise("PUT", `/setting`, setting);
};

let putLostItem = (item) => {
  return fetchWithAuthPromise("PUT", `/lost`, item);
};

let putFoundItem = (item) => {
  return fetchWithAuthPromise("PUT", `/found`, item);
};

let putFoundImage = (image) => {
  return fetchWithAuthPromise("PUT", `/foundimage`, image);
};

let postUser = (user) => {
  return fetchWithAuthPromise("POST", `/users`, user);
};

let postSetting = (setting) => {
  return fetchWithAuthPromise("POST", `/setting`, setting);
};

let postLostItem = (item, callback) => {
  fetchWithoutAuth("POST", `/lost?notify=true`, callback, item);
};

let postFoundItem = (item) => {
  return fetchWithAuthPromise("POST", `/found`, item);
};

let postSale = (item) => {
  return fetchWithAuthPromise("POST", `/sale`, item);
};

let postSaleItem = (item) => {
  return fetchWithAuthPromise("POST", `/sale/${item.sale_id}/saleitem`, item);
};

let deleteUser = (id) => {
  return fetchWithAuthPromise("DELETE", `/users/${id}`);
};

let deleteSetting = (id) => {
  return fetchWithAuthPromise("DELETE", `/setting/${id}`);
};

let deleteLostItem = (id) => {
  return fetchWithAuthPromise("DELETE", `/lost/${id}`);
};

let deleteFoundItem = (id) => {
  return fetchWithAuthPromise("DELETE", `/found/${id}`);
};

let deleteFoundImage = (id) => {
  return fetchWithAuthPromise("DELETE", `/foundimage/${id}`);
};

let bulkUpdate = (target, field, value, ids, callback) => {
  fetchWithAuth("PUT", `/bulk`, callback, {
    target: target,
    field: field,
    value: value,
    ids: ids,
  });
};

let sendEmail = (msg, subject, to, html, callback) => {
  fetchWithAuth("POST", `/email`, callback, {
    msg: msg,
    subject: subject,
    to: to,
    html: html,
  });
};

let fetchWithoutAuth = (method, url, callback, body = false) => {
  let options = {
    method: method,
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchWithoutAuthPromise = (method, url, body = false) => {
  let options = {
    method: method,
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      if (response.status === 400) {
        return response.json();
      }
      console.log(response);
      throw new Error("Network response failed.");
    })
    .catch(_errorHandler);
};

let fetchWithAuth = (method, url, callback, body = false) => {
  let options = {
    method: method,
    headers: {
      Authorization: "Bearer " + window.localStorage.getItem("id_token"),
    },
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      console.log(response);
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchWithAuthPromise = (method, url, body = false) => {
  let options = {
    method: method,
    headers: {
      Authorization: "Bearer " + window.localStorage.getItem("id_token"),
    },
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      if (response.status === 400) {
        return response.json();
      }
      if (response.status === 401) {
        return response.json().then((e) => {
          console.log(e);
          if (e.error === "expired token") {
            let message =
              "Looks like your session has expired, please log back in to renew.";
            window.localStorage.clear();
            window.location.href = `/warning/${encodeURIComponent(
              message
            ).replace(/\./g, "%2E")}`;
          } else {
            throw new Error(e.error);
          }
        });
      }
      throw new Error("Network response failed.");
    })
    .catch(_errorHandler);
};

let _errorHandler = (error) => {
  console.error(error);
  if (
    (error.name === "TypeError" &&
      [
        "Failed to fetch",
        "NetworkError when attempting to fetch resource.",
      ].includes(error.message)) ||
    (error.name === "DOMException" &&
      ["The operation was aborted."].includes(error.message))
  ) {
    return;
  }

  let message =
    "There seems to be an issue with your token, please log back in and try again";
  if (error.message === "invalid user") {
    message =
      "Unauthorized user. If you are a Lost Found employee and require a login, please contact vu.its@wwu.edu";
  } else if (error.message === "invalid configuration") {
    message =
      "Oh no, there seems to be an issue with the authentication server. Please contact vu.its@wwu.edu";
  } else if (error.message === "invalid claims") {
    message =
      "Oh no, there seems to be an issue with the auth provider. Please contact vu.its@wwu.edu";
  } else if (error.message === "expired token") {
    message = "Your session has expired, please log back in to renew.";
    window.localStorage.clear();
    window.location.href = `/warning/${encodeURIComponent(message).replace(
      /\./g,
      "%2E"
    )}`;
  } else {
    window.localStorage.clear();
    window.location.href = `/error/${encodeURIComponent(message).replace(
      /\./g,
      "%2E"
    )}`;
  }
};

export {
  fetchSelf,
  fetchSettings,
  fetchSetting,
  fetchUsers,
  fetchLost,
  fetchFound,
  fetchFoundImage,
  fetchReport,
  fetchLostItem,
  fetchFoundItem,
  fetchTicket,
  fetchDepartments,
  fetchCategories,
  fetchSubcategories,
  fetchAttributes,
  fetchLocations,
  fetchLostMatches,
  fetchFoundMatches,
  fetchAllSubcategories,
  fetchSalesReport,
  fetchSales,
  fetchSaleItems,
  putLostItem,
  putFoundItem,
  putFoundImage,
  putUser,
  putSetting,
  putSubcategories,
  postLostItem,
  postFoundItem,
  postUser,
  postSetting,
  postSale,
  postSaleItem,
  deleteUser,
  deleteSetting,
  deleteLostItem,
  deleteFoundItem,
  deleteFoundImage,
  bulkUpdate,
  sendEmail,
};
