import React from "react";
import { isAuthenticated } from "./services/AuthService";

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    var selectedIndex = 0;
    let path = window.location.pathname.split("/")[1];
    if (path === "lost") {
      selectedIndex = 1;
    } else if (path === "found") {
      selectedIndex = 2;
    } else if (path === "report") {
      selectedIndex = 3;
    } else if (path === "admin") {
      selectedIndex = 4;
    }
    let user = localStorage.getItem("user");
    if (user !== null) {
      user = JSON.parse(user);
    }

    this.state = {
      selectedIndex: selectedIndex,
      user: user,
    };
  }

  render() {
    if (!isAuthenticated()) {
      return <div></div>;
    }
    return (
      <div id="main-menu">
        <div className="region region-navigation">
          <div
            id="block-system-main-menu"
            className="block block-system block-system-main-menu block-menu first last odd"
          >
            <ul className="menu">
              <li className="menu__item is-leaf first leaf">
                <a
                  href="/"
                  className={
                    this.state.selectedIndex === 0
                      ? "menu__link active glyphicon glyphicon-home"
                      : "menu__link glyphicon glyphicon-home"
                  }
                >
                  <div className="hidden">Home</div>
                </a>
              </li>
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/lost"
                  className={
                    this.state.selectedIndex === 1
                      ? "menu__link active"
                      : "menu__link"
                  }
                >
                  Lost
                </a>
              </li>
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/found"
                  className={
                    this.state.selectedIndex === 2
                      ? "menu__link active"
                      : "menu__link"
                  }
                >
                  Found
                </a>
              </li>
              {this.state.user !== null && (this.state.user.is_superuser || this.state.user.is_reporter) ? (
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/report"
                  className={
                    this.state.selectedIndex === 3
                      ? "menu__link active"
                      : "menu__link"
                  }
                >
                  Report
                </a>
              </li>
              ) : ""}
              {this.state.user !== null && this.state.user.is_superuser ? (
                <li className="menu__item is-collapsed collapsed">
                  <a
                    href="/admin"
                    className={
                      this.state.selectedIndex === 4
                        ? "menu__link active"
                        : "menu__link"
                    }
                  >
                    Admin
                  </a>
                </li>
              ) : (
                ""
              )}
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="https://wwu2.sharepoint.com/:o:/s/VikingUnionTechServices/EiUhuTv9yZxNj3EPDnyBmqAB5fZeBdelRkhj32d9h8iaTA"
                  className="menu__link glyphicon glyphicon-question-sign"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="hidden">Documentation</div>
                </a>
              </li>
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/logout"
                  className="menu__link glyphicon glyphicon-log-out"
                >
                  <div className="hidden">Logout</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MainMenu;
