import QrReader from "react-qr-scanner";
import { Button } from "react-bootstrap";

const Scanner = (props) => (
  <div className="scanner">
    <QrReader
      delay={500}
      onError={(err) => {
        console.error(err);
        props.onDetected("");
      }}
      onScan={(data) => {
        if (data) props.onDetected(data.text);
      }}
      style={{ width: "100%" }}
    />
    <Button
      variant="danger"
      onClick={props.onClose}
      className="glyphicon glyphicon-remove"
    >
      <div className="hidden">QR Scanner</div>
    </Button>
  </div>
);

export default Scanner;
