import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import DeleteAlert from "./DeleteAlert";

class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAlert: false,
      first_name: props.user ? props.user.first_name : null,
      last_name: props.user ? props.user.last_name : null,
      email: props.user ? props.user.email : null,
      is_active: props.user ? props.user.is_active : null,
      is_reporter: props.user ? props.user.is_reporter : null,
      is_superuser: props.user ? props.user.is_superuser : null,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    let deleteButton;
    if (this.props.user) {
      deleteButton = (
        <div className="d-flex justify-content-center btn-delete">
          <Button
            variant="danger"
            onClick={() => {
              this.setState({ deleteAlert: true });
            }}
          >
            Delete
          </Button>
        </div>
      );
    }
    let deleteAlert;
    if (this.state.deleteAlert) {
      deleteAlert = (
        <DeleteAlert
          onConfirm={() => {
            this.props.onDelete(this.props.user.id);
          }}
          onCancel={() => this.setState({ deleteAlert: false })}
        />
      );
    }
    return (
      <>
        {deleteAlert}
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
          className="user-modal"
        >
          <Modal.Header>
            <Modal.Title>{this.props.user ? "Edit" : "Add"} a User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="form" noValidate>
              <Form.Group as={Row}>
                <Form.Label column xs="1" sm="2">
                  First
                </Form.Label>
                <Col xs="4" sm="2" md="3">
                  <Form.Control
                    required
                    type="text"
                    name="first_name"
                    placeholder="Viking"
                    onChange={this.onChange}
                    value={this.state.first_name || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a name.
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column xs="1" sm="2">
                  Last
                </Form.Label>
                <Col xs="4" sm="2" md="3">
                  <Form.Control
                    required
                    type="text"
                    name="last_name"
                    placeholder="McRealname"
                    onChange={this.onChange}
                    value={this.state.last_name || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column xs="2" sm="2">
                  Email
                </Form.Label>
                <Col xs="6" sm="6" md="8">
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    placeholder="viking@wwu.edu"
                    onChange={this.onChange}
                    value={this.state.email || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an email.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column xs="1" sm="1">
                  Active
                </Form.Label>
                <Col xs="2" sm="2" md="2">
                  <Form.Check
                    className="inline-checkbox"
                    type="checkbox"
                    checked={this.state.is_active}
                    onChange={() => {
                      this.setState({ is_active: !this.state.is_active });
                    }}
                  />
                </Col>
                <Col sm="1"></Col>
                <Form.Label column xs="1" sm="1">
                  Report
                </Form.Label>
                <Col xs="2" sm="2" md="2">
                  <Form.Check
                    className="inline-checkbox"
                    type="checkbox"
                    checked={this.state.is_reporter}
                    onChange={() => {
                      this.setState({ is_reporter: !this.state.is_reporter });
                    }}
                  />
                </Col>
                <Col sm="1"></Col>
                <Form.Label column xs="1" sm="1">
                  Admin
                </Form.Label>
                <Col xs="2" sm="2" md="2">
                  <Form.Check
                    className="inline-checkbox"
                    type="checkbox"
                    checked={this.state.is_superuser}
                    onChange={() => {
                      this.setState({ is_superuser: !this.state.is_superuser });
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {deleteButton}
            <Button
              variant="secondary"
              className="btn-modal-cancel"
              onClick={this.props.onHide}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn-modal-submit"
              onClick={() => {
                const user = {
                  id: this.props.user ? this.props.user.id : null,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  email: this.state.email,
                  is_active: this.state.is_active,
                  is_reporter: this.state.is_reporter,
                  is_superuser: this.state.is_superuser,
                };
                this.props.onSubmit(user);
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UserModal;
