import React from "react";
import { Toast } from "react-bootstrap";

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: props.show
    };
    this.closeToast = this.closeToast.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showToast !== this.props.showToast) {
      this.setState({ showToast: this.props.showToast });
    }
  }

  closeToast() {
    this.setState({ showToast: false });
  }

  render() {
    return (
      <Toast
        show={this.state.showToast}
        onClose={this.closeToast}
        autohide={true}
        delay={5000}
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 1000
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{this.props.message}</Toast.Body>
      </Toast>
    );
  }
}

export default Notification;
