import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/App.scss";
import "./style/glyphicon.scss";
import Home from "./Home.js";
import Lost from "./Lost.js";
import Found from "./Found";
import AddLost from "./AddLost";
import AddFound from "./AddFound";
import Callback from "./Callback";
import Report from "./Report";
import Sale from "./Sale";
import SaleReport from "./SaleReport";
import Admin from "./Admin";
import Header from "./Header";
import Footer from "./Footer";
import Error from "./Error";
import Warning from "./Warning";
import RouteError from "./RouteError";
import PrivateRoute from "./PrivateRoute";
import { Login, Logout } from "./services/AuthService";

const handlePopState = (event) => {
  event.preventDefault();
};

function App() {
  window.onpopstate = handlePopState;
  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/found/:id"
            element={
              <PrivateRoute>
                <AddFound />
              </PrivateRoute>
            }
          />
          <Route
            path="/found/add/"
            element={
              <PrivateRoute>
                <AddFound />
              </PrivateRoute>
            }
          />
          <Route
            path="/found/"
            element={
              <PrivateRoute>
                <Found />
              </PrivateRoute>
            }
          />
          <Route
            path="/sale/"
            element={
              <PrivateRoute>
                <Sale />
              </PrivateRoute>
            }
          />
          <Route
            path="/sale/report/"
            element={
              <PrivateRoute>
                <SaleReport />
              </PrivateRoute>
            }
          />
          <Route path="/lost/add/" element={<AddLost />} />
          <Route
            path="/lost/:id"
            element={
              <PrivateRoute>
                <AddLost />
              </PrivateRoute>
            }
          />
          <Route
            path="/lost/"
            element={
              <PrivateRoute>
                <Lost />
              </PrivateRoute>
            }
          />
          <Route
            path="/report/"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route path="/login/" element={<Login />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/callback/" element={<Callback />} />
          <Route path="/error/:msg" element={<Error />} />
          <Route path="/warning/:msg" element={<Warning />} />
          <Route path="*" element={<RouteError />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
