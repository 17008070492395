import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

const ResolutionSelect = (props) => {
  return (
    <ToggleButtonGroup
      type="radio"
      name="resolution"
      className={`col-sm-12 resolution-group ${props.className}`}
      value={props.resolution}
      aria-label="Resolution Buttons"
    >
      <ToggleButton
        variant="outline-primary"
        value={1}
        onClick={() => props.onResolutionChange(1)}
      >
        Sold
      </ToggleButton>
      <ToggleButton
        variant="outline-primary"
        value={2}
        onClick={() => props.onResolutionChange(2)}
      >
        Returned
      </ToggleButton>
      <ToggleButton
        variant="outline-primary"
        value={3}
        onClick={() => props.onResolutionChange(3)}
      >
        Disposed
      </ToggleButton>
      <ToggleButton
        variant="outline-primary"
        value={5}
        onClick={() => props.onResolutionChange(5)}
      >
        Donated
      </ToggleButton>
      <ToggleButton
        variant="outline-primary"
        value={4}
        onClick={() => props.onResolutionChange(4)}
      >
        In Inventory
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ResolutionSelect;
