import React from "react";
import { FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { attributesFromString } from "../services/UtilityService";
import { CirclePicker } from "react-color";

class AttributeFields extends React.Component {
  constructor(props) {
    super(props);
    this.onAttributesChange = this.onAttributesChange.bind(this);
    this.onPrimaryColorChange = this.onPrimaryColorChange.bind(this);
    this.onSecondaryColorChange = this.onSecondaryColorChange.bind(this);
  }

  onPrimaryColorChange(color, event) {
    document.getElementById(
      "primary-color-indicator"
    ).style = `background-color:${event.target.title}`;
    document.getElementById("primary-color-indicator").title =
      event.target.title;
    this.props.onAttributesChange(
      "attribute_Primary Color",
      event.target.title
    );
  }

  onSecondaryColorChange(color, event) {
    document.getElementById(
      "secondary-color-indicator"
    ).style = `background-color:${event.target.title}`;
    document.getElementById("secondary-color-indicator").title =
      event.target.title;
    this.props.onAttributesChange(
      "attribute_Secondary Color",
      event.target.title
    );
  }

  onAttributesChange(event) {
    this.props.onAttributesChange(event.target.name, event.target.value);
  }

  render() {
    if (this.props.attributes.length === 0) {
      return <></>;
    }
    let selected = attributesFromString(this.props.selected);
    return (
      <div>
        {this.props.attributes.map((attribute) => {
          if (attribute.Cust.String === "color") {
            return (
              <Row key={attribute.ID} style={{ marginBottom: "4px" }}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                  <div
                    id={
                      attribute.Attr === "Primary Color"
                        ? "primary-color-indicator"
                        : "secondary-color-indicator"
                    }
                    style={{ backgroundColor: `${selected[attribute.Attr]}` }}
                  ></div>
                </FormLabel>
                <Col sm="9">
                  <CirclePicker
                    name={"attribute_" + attribute.ID}
                    color={selected[attribute.Attr]}
                    onChange={
                      attribute.Attr === "Primary Color"
                        ? this.onPrimaryColorChange
                        : this.onSecondaryColorChange
                    }
                    colors={[
                      "hotpink",
                      "purple",
                      "blue",
                      "lightblue",
                      "green",
                      "lightgreen",
                      "yellowgreen",
                      "yellow",
                      "orange",
                      "red",
                      "brown",
                      "grey",
                      "white",
                      "black",
                      "gold",
                      "silver",
                      "copper",
                      "clear",
                    ]}
                  />
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "dvd-or-bluray") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="dvd" key="1">
                      DVD
                    </option>
                    <option value="blu-ray" key="2">
                      Blu-ray
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "music-or-media") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="music" key="1">
                      Music
                    </option>
                    <option value="media" key="2">
                      Media
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "credit-or-debit") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="credit" key="1">
                      Credit
                    </option>
                    <option value="debit" key="2">
                      Debit
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "headphone-type") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="on-ear" key="1">
                      On-Ear
                    </option>
                    <option value="over-ear" key="2">
                      Over-Ear
                    </option>
                    <option value="in-ear" key="3">
                      In-Ear
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "wired-or-wireless") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="wired" key="1">
                      Wired
                    </option>
                    <option value="wireless" key="2">
                      Wireless
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          if (attribute.Cust.String === "fringe") {
            return (
              <Row key={attribute.ID}>
                <FormLabel column sm="3">
                  {attribute.Attr}
                </FormLabel>
                <Col sm="9">
                  <FormControl
                    as="select"
                    name={"attribute_" + attribute.Attr}
                    onChange={this.onAttributesChange}
                    defaultValue={selected[attribute.Attr]}
                  >
                    <option value="0" key="0">
                      Select an option
                    </option>
                    <option value="fringe" key="1">
                      Fringe
                    </option>
                    <option value="no fringe" key="2">
                      No Fringe
                    </option>
                  </FormControl>
                  <FormControl.Feedback type="invalid">
                    {attribute.Desc}
                  </FormControl.Feedback>
                </Col>
              </Row>
            );
          }
          return (
            <Row key={attribute.ID}>
              <FormLabel column sm="3">
                {attribute.Attr}
              </FormLabel>
              <Col sm="9">
                <FormControl
                  type="text"
                  name={"attribute_" + attribute.Attr}
                  onChange={this.onAttributesChange}
                  defaultValue={selected[attribute.Attr]}
                />
                <FormControl.Feedback type="invalid">
                  {attribute.Desc}
                </FormControl.Feedback>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default AttributeFields;
