import React from "react";
import { Spinner } from "react-bootstrap";

const LoadMask = () => (
  <Spinner animation="grow" variant="primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
);

export default LoadMask;
