import React from "react";

import { Chart } from "react-google-charts";

export function SaleChart(props) {
  const options = {
    legend: { position: "none" },
  };
  return (
    <Chart
      chartType="Line"
      width="100%"
      height="400px"
      data={props.data}
      options={options}
    />
  );
}
