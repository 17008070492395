import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import DeleteAlert from "./DeleteAlert";

class SettingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAlert: false,
      name: props.setting ? props.setting.name : null,
      value: props.setting ? props.setting.value : null,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    let deleteButton;
    if (this.props.setting) {
      deleteButton = (
        <div className="d-flex justify-content-center btn-delete">
          <Button
            variant="danger"
            onClick={() => {
              this.setState({ deleteAlert: true });
            }}
          >
            Delete
          </Button>
        </div>
      );
    }
    let deleteAlert;
    if (this.state.deleteAlert) {
      deleteAlert = (
        <DeleteAlert
          onConfirm={() => {
            this.props.onDelete(this.props.setting.id);
          }}
          onCancel={() => this.setState({ deleteAlert: false })}
        />
      );
    }
    return (
      <>
        {deleteAlert}
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
          className="user-modal"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.setting ? "Edit" : "Add"} a Setting
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="form" noValidate>
              <Form.Group as={Row}>
                <Form.Label column xs="1" sm="2">
                  Name
                </Form.Label>
                <Col xs="4" sm="2" md="3">
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    onChange={this.onChange}
                    value={this.state.name || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a name.
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column xs="1" sm="2">
                  Value
                </Form.Label>
                <Col xs="4" sm="2" md="3">
                  <Form.Control
                    required
                    type="text"
                    name="value"
                    onChange={this.onChange}
                    value={this.state.value || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a value.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {deleteButton}
            <Button
              variant="secondary"
              className="btn-modal-cancel"
              onClick={this.props.onHide}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn-modal-submit"
              onClick={() => {
                let setting = {
                  id: this.props.setting ? this.props.setting.id : null,
                  name: this.state.name,
                  value: this.state.value,
                };
                this.props.onSubmit(setting);
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SettingModal;
