import React from "react";
import { Button, Image } from "react-bootstrap";
import "./style/home.scss";
import infodesk from "./images/lostfound.png";

const Home = () => {
  return (
    <div>
      <div className="top-section">
        <div className="add-lost">
          <div className="label">Lose something?</div>
          <div className="top-desc">
            Visit the Lost and Found near the elevators on the VU 6th Floor
            Lobby to claim your item, or submit a Lost Item Report.
          </div>
          <div className="add-lost-button-container">
            <Button href="/lost/add" className="add-lost-button wwu-button">
              Report
            </Button>
          </div>
        </div>
        <div className="infodesk">
          <Image src={infodesk} />
        </div>
      </div>

      <div className="bottom-section">
        <div className="light-blue-bg"></div>
        <div className="desc">
          <div className="desc-title label bold">Lost &amp; Found</div>
          <div className="desc-info">
            The Viking Union Lost and Found exists to help people reunite with
            their lost items. It is located at the Info Desk in the VU 6th Floor
            Lobby.
          </div>
          <div className="find">
            <div className="label">Find something?</div>Bring it to the Info
            Desk in the VU 6th Floor Lobby, and we will try to return it to its
            rightful owner. All found items submitted to the Lost and Found are
            logged and stored securely.
          </div>
        </div>
        <div className="hours">
          <div className="label">Hours</div>
          <div className="hours-times">Mon-Sat 9:00am-9:00pm</div>
          <div className="hours-times">Sun 10:00am-9:00pm</div>
          <div className="hours-addendum">
            Closed during intersession breaks and holidays
          </div>
        </div>
        <div className="contact">
          <div className="label">Contact</div>
          <div className="contact-info">p | 360.650.3120</div>
          <div className="contact-info">
            e | <a href="mailto:vu.lostfound@wwu.edu">vu.lostfound@wwu.edu</a>
          </div>
        </div>
        <div className="grey-bg"></div>
        <div className="login">
          <div className="label">Lost & Found Employee?</div>
          <div className="login-info">Login as an employee</div>
          <div className="button-container">
            <Button href="/login" className="login-button wwu-button">
              Login
            </Button>
          </div>
        </div>
      </div>
      <div className="policy">
        <a href="https://vu.wwu.edu/lost-and-found">View our policy</a>
      </div>
    </div>
  );
};

export default Home;
