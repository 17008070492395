import React from "react";
import { Alert, Button, ButtonToolbar, Form, Image } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Search from "./Search";
import { stringToDate } from "./services/UtilityService";
import {
  fetchReport,
  fetchCategories,
  fetchSubcategories,
  fetchLocations,
  fetchDepartments,
  bulkUpdate,
} from "./services/DataService";
import { LocationFormatter } from "./components/formatters/Formatters";
import MultiSelectTable from "./components/MultiSelectTable";
import LoadMask from "./components/LoadMask";
import "./style/report.scss";

class Report extends React.Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    if (user !== null) {
      user = JSON.parse(user);
    }
    this.state = {
      user: user,
      items: [],
      filtered: [],
      selected: [],
      locations: [],
      departmentsList: [],
      searchTerms: [],
      catID: 0,
      subCatID: 0,
      resolutionID: 0,
      beforeDate: false,
      afterDate: false,
      alert: false,
      redirect: false,
      loading: true,
      maxResults: 1000,
      resultCount: 0,
      department_id: user.department_id,
      is_manager: (user.email === process.env.REACT_APP_SUPER || user.is_reporter),
    };
    this.getFound = this.getFound.bind(this);
    this.locationFormatter = this.locationFormatter.bind(this);
    this.confirmBulk = this.confirmBulk.bind(this);
    this.confirmBulkLocation = this.confirmBulkLocation.bind(this);
    this.confirmBulkDepartment = this.confirmBulkDepartment.bind(this);
    this.bulkChange = this.bulkChange.bind(this);
    this.bulkChangeCallback = this.bulkChangeCallback.bind(this);
    this.catID = "0";
    this.subCatID = "0";
    this.resolutions = {
      1: "Sold",
      2: "Returned",
      3: "Disposed",
      4: "In Inventory",
      5: "Donated",
    };
  }

  componentWillMount() {
    if (this.state.is_manager) {
      this.setState({ department_id: 0 });
    }
    Promise.all([
      fetchCategories(),
      fetchSubcategories("0"),
      fetchLocations(),
      fetchDepartments(),
    ])
      .then(([categories, subcategories, locations, departments]) => {
        let categoryMap = {};
        categories.forEach((category) => {
          categoryMap[category["ID"]] = category["Name"];
        });
        let subcategoryMap = {};
        subcategories.forEach((subcategory) => {
          subcategoryMap[subcategory["ID"]] = subcategory["Name"];
        });
        let locationMap = {};
        locations.forEach((location) => {
          locationMap[location["ID"]] = location["Name"];
        });
        let departmentsMap = {};
        departments.forEach((department) => {
          departmentsMap[department.ID] = department.Name;
        });
        this.setState(
          {
            categories: categoryMap,
            subcategories: subcategoryMap,
            locations: locationMap,
            departments: departmentsMap,
            departmentsList: departments,
          },
          this.getFound
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  confirmBulk(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      alert: true,
      bulkField: event.target.name,
      bulkValue: parseInt(event.target.value),
      bulkValueString: event.target.innerText,
    });
  }

  confirmBulkLocation(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      alert: true,
      bulkField: event.target.name,
      bulkValue: parseInt(event.target.value),
      bulkValueString: this.state.locations[parseInt(event.target.value)],
    });
  }

  confirmBulkDepartment(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      alert: true,
      bulkField: event.target.name,
      bulkValue: parseInt(event.target.value),
      bulkValueString: this.state.departments[parseInt(event.target.value)],
    });
  }

  bulkChange() {
    if (this.state.selected.length) {
      bulkUpdate(
        "found",
        this.state.bulkField,
        this.state.bulkValue,
        this.state.selected,
        this.bulkChangeCallback
      );
    } else {
      this.bulkChangeCallback();
    }
  }

  bulkChangeCallback() {
    this.setState({ alert: false, selected: [] }, this.getFound);
  }

  getFound() {
    let params = [];
    if (this.state.department_id && this.state.department_id !== "0") {
      params.push("department=" + this.state.department_id);
    }
    if (this.catID !== "0") {
      params.push("category=" + this.catID);
    }
    if (this.subCatID !== "0") {
      params.push("subcategory=" + this.subCatID);
    }
    if (this.state.resolutionID !== 0) {
      params.push("resolution=" + this.state.resolutionID);
    }
    fetchReport(params).then((items) => {
      this.setState({ items: items }, this.applyFilters);
    });
  }

  applyFilters() {
    let filtered = this.state.items;
    if (this.state.beforeDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) <= this.state.beforeDate
      );
    }
    if (this.state.afterDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) >= this.state.afterDate
      );
    }
    if (this.state.location && this.state.location !== "0") {
      filtered = filtered.filter(
        (item) => item.LocationID === parseInt(this.state.location)
      );
    }
    this.state.searchTerms.map((term) => {
      return (filtered = filtered.filter((item) =>
        `${this.state.categories[
          item.CatID
        ].toLowerCase()} ${this.state.subcategories[
          item.SubCatID
        ].toLowerCase()} ${item.Where.toLowerCase()} ${item.Ticket.toLowerCase()}`.includes(
          term
        )
      ));
    });
    this.setState({
      loading: false,
      resultCount: filtered.length,
      filtered: filtered.slice(0, this.state.maxResults),
    });
  }

  imageFormatter = (found) => (
    <Image
      src={`${process.env.REACT_APP_BLOB_URL}/images/found/${found.ID}`}
      className="thumbnail"
      onError={(e) => {
        e.target.onerror = null;
        e.target.classList.add("card-svg");
        e.target.src = require(`./images/subcategories/${found.SubCatID}.svg`);
      }}
    />
  );

  locationFormatter = (found) => {
    if (found.DepartmentID !== this.state.user.department_id) {
      return <div>{this.state.departments[found.DepartmentID]}</div>;
    }
    return (
      <LocationFormatter location={this.state.locations[found.LocationID]} />
    );
  };

  render() {
    if (this.state.redirect) return <Navigate to={this.state.redirect} />;
    if (this.state.loading) return <LoadMask />;

    let alert;
    if (this.state.alert) {
      alert = (
        <Alert className="resolutionAlert" variant="danger">
          <Alert.Heading>Bulk Update</Alert.Heading>
          <hr />
          <div className="d-flex">
            Update {this.state.bulkField} to {this.state.bulkValueString} for
            the selected {this.state.selected.length} items?
          </div>
          <div className="d-flex justify-content-end">
            <Button
              style={{ marginRight: "1em", marginTop: "2em" }}
              onClick={this.bulkChange}
              variant="outline-danger"
            >
              Confirm
            </Button>
            <Button
              style={{ marginRight: "1em", marginTop: "2em" }}
              onClick={() => this.setState({ alert: false })}
              variant="danger"
            >
              Cancel
            </Button>
          </div>
        </Alert>
      );
    }

    let bulkOperations = (
      <div
        style={{
          height: "70px",
          paddingTop: "8px",
          margin: "0 12px 0 2vw",
        }}
      >
        {this.state.resultCount !== 0
          ? this.state.filtered.length === this.state.resultCount
            ? "Showing all " + this.state.resultCount + " results"
            : `Showing ` +
              this.state.filtered.length +
              "/" +
              this.state.resultCount +
              " results"
          : `No items found`}
      </div>
    );
    if (this.state.selected.length) {
      bulkOperations = (
        <div className="report-toolbar">
          <div style={{ paddingTop: "0", margin: "0 12px 0 2vw" }}>
            {this.state.selected.length} selected
          </div>
          <ButtonToolbar>
            <Button
              onClick={this.confirmBulk}
              value="1"
              name="resolution"
              variant="danger"
            >
              Sold
            </Button>
            <Button
              onClick={this.confirmBulk}
              value="2"
              name="resolution"
              variant="success"
            >
              Returned
            </Button>
            <Button
              onClick={this.confirmBulk}
              value="3"
              name="resolution"
              variant="warning"
            >
              Disposed
            </Button>
            <Button
              onClick={this.confirmBulk}
              value="5"
              name="resolution"
              variant="info"
            >
              Donated
            </Button>
            <Button
              onClick={this.confirmBulk}
              value="4"
              name="resolution"
              variant="primary"
            >
              In Inventory
            </Button>
          </ButtonToolbar>
          <Form.Group controlId="reportForm.location">
            <Form.Control
              as="select"
              name="location"
              onChange={this.confirmBulkLocation}
            >
              <option key="0" value="0" defaultValue>
                Update Location
              </option>
              {Object.keys(this.state.locations).map((id, index) => {
                return (
                  <option key={id} value={id}>
                    {this.state.locations[id]}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="reportForm.department">
            <Form.Control
              as="select"
              name="department"
              onChange={this.confirmBulkDepartment}
            >
              <option key="0" value="0" defaultValue>
                Update Department
              </option>
              {Object.keys(this.state.departments).map((id, index) => {
                if (
                  parseInt(id) === this.state.user.department_id &&
                  !this.state.is_manager
                ) {
                  return "";
                }
                return (
                  <option key={id} value={id}>
                    {this.state.departments[id]}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
      );
    }

    return (
      <div>
        {alert}
        <Search
          onCategoryChange={(c) => {
            this.catID = c;
            this.subCatID = "0";
            this.getFound();
          }}
          onSubcategoryChange={(c) => {
            this.subCatID = c;
            this.getFound();
          }}
          onResolutionChange={(r) =>
            this.setState({ resolutionID: r }, this.getFound)
          }
          onSearchChange={(terms) =>
            this.setState(
              { searchTerms: terms.toLowerCase().split(" ") },
              this.applyFilters
            )
          }
          onBeforeDateChange={(date) =>
            this.setState({ beforeDate: date }, this.applyFilters)
          }
          onAfterDateChange={(date) =>
            this.setState({ afterDate: date }, this.applyFilters)
          }
          onLocationChange={(l) =>
            this.setState({ location: l }, this.getFound)
          }
          locations={this.state.locations}
          onDepartmentChange={
            this.state.is_manager
              ? (d) => this.setState({ department_id: d }, this.getFound)
              : false
          }
          departments={
            this.state.is_manager ? this.state.departmentsList : false
          }
        />
        {bulkOperations}
        <MultiSelectTable
          className="report-table"
          headers={[
            { label: "", dataKey: "image" },
            { label: "Ticket", dataKey: "ticket" },
            { label: "Category", dataKey: "category" },
            { label: "Subcategory", dataKey: "subcategory" },
            { label: "Resolution", dataKey: "resolution" },
            { label: "Location", dataKey: "location" },
            { label: "Where", dataKey: "where" },
            { label: "When", dataKey: "when" },
          ]}
          data={this.state.filtered.map((found) => {
            return {
              id: found.ID,
              image: this.imageFormatter(found),
              ticket: found.Ticket,
              category: this.state.categories[found.CatID],
              subcategory: this.state.subcategories[found.SubCatID],
              resolution: this.resolutions[found.ResolutionID],
              location: this.locationFormatter(found),
              where: found.Where,
              when: found.When,
            };
          })}
          sortableColumns={["ticket", "location", "where", "when"]}
          onSelect={(selected) =>
            this.setState({ selected: selected.map((s) => s.id) })
          }
          onDoubleClick={(selected) =>
            this.setState({ redirect: `/found/${selected.id}` })
          }
        />
      </div>
    );
  }
}

export default Report;
