import withRouter from "./withRouter";
import ErrorAlert from "./components/ErrorAlert";

const Error = (props) => (
  <div>
    <ErrorAlert
      variant="danger"
      header="An error has occurred"
      error={
        decodeURIComponent(props.params.msg) || "Please close and try again"
      }
      onClose={() => (window.location.href = "/")}
    />
  </div>
);

export default withRouter(Error);
